export class Verse {
  // words structure
  // { "i": 0, "text": "", "word": "וַיְהִי֩", "number": "h1961" }[]
  constructor(number, data) {
    this.number = number;
    this.data = data;
  }

  containsWord(word) {
    return this.data.verse.some((wordData) => wordData.number === word);
  }
}
