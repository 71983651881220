import amosData from "../bible_data/amos.json";
import danielData from "../bible_data/daniel.json";
import duetData from "../bible_data/deuteronomy.json";
import ecclesiastesData from "../bible_data/ecclesiastes.json";
import estherData from "../bible_data/esther.json";
import exodusData from "../bible_data/exodus.json";
import ezekielData from "../bible_data/ezekiel.json";
import ezraData from "../bible_data/ezra.json";
import genesisData from "../bible_data/genesis.json";
import habakkukData from "../bible_data/habakkuk.json";
import haggaiData from "../bible_data/haggai.json";
import hoseaData from "../bible_data/hosea.json";
import iChronData from "../bible_data/i_chronicles.json";
import iKingsData from "../bible_data/i_kings.json";
import iSamuelData from "../bible_data/i_samuel.json";
import iiChronData from "../bible_data/ii_chronicles.json";
import iiKingsData from "../bible_data/ii_kings.json";
import iiSamuelData from "../bible_data/ii_samuel.json";
import isaiahData from "../bible_data/isaiah.json";
import jeremiahData from "../bible_data/jeremiah.json";
import jobData from "../bible_data/job.json";
import joelData from "../bible_data/joel.json";
import jonahData from "../bible_data/jonah.json";
import joshuaData from "../bible_data/joshua.json";
import judgesData from "../bible_data/judges.json";
import lamentationsData from "../bible_data/lamentations.json";
import leviticusData from "../bible_data/leviticus.json";
import malachiData from "../bible_data/malachi.json";
import micahData from "../bible_data/micah.json";
import nahumData from "../bible_data/nahum.json";
import nehemiahData from "../bible_data/nehemiah.json";
import numbersData from "../bible_data/numbers.json";
import obadiahData from "../bible_data/obadiah.json";
import proverbsData from "../bible_data/proverbs.json";
import psalmsData from "../bible_data/psalms.json";
import ruthData from "../bible_data/ruth.json";
import songOfSolomonData from "../bible_data/song_of_solomon.json";
import zechariahData from "../bible_data/zechariah.json";
import zephaniahData from "../bible_data/zephaniah.json";
import { parseBookData } from "./parse";

export function getOldTestamentBooks() {
  const genesis = parseBookData(genesisData, "Genesis");
  const exodus = parseBookData(exodusData, "Exodus");
  const leviticus = parseBookData(leviticusData, "Leviticus");
  const numbers = parseBookData(numbersData, "Numbers");
  const duet = parseBookData(duetData, "Deuteronomy");
  const joshua = parseBookData(joshuaData, "Joshua");
  const judges = parseBookData(judgesData, "Judges");
  const ruth = parseBookData(ruthData, "Ruth");
  const iSamuel = parseBookData(iSamuelData, "1 Samuel");
  const iiSamuel = parseBookData(iiSamuelData, "2 Samuel");
  const iKings = parseBookData(iKingsData, "1 Kings");
  const iiKings = parseBookData(iiKingsData, "2 Kings");
  const iChron = parseBookData(iChronData, "1 Chron");
  const iiChron = parseBookData(iiChronData, "2 Chron");
  const ezra = parseBookData(ezraData, "Ezra");
  const nehemiah = parseBookData(nehemiahData, "Nehemiah");
  const esther = parseBookData(estherData, "Esther");
  const job = parseBookData(jobData, "Job");
  const psalms = parseBookData(psalmsData, "Psalms");
  const proverbs = parseBookData(proverbsData, "Proverbs");
  const ecclesiastes = parseBookData(ecclesiastesData, "Ecclesiastes");
  const songOfSolomon = parseBookData(songOfSolomonData, "Song Of Solomon");
  const isaiah = parseBookData(isaiahData, "Isaiah");
  const jeremiah = parseBookData(jeremiahData, "Jeremiah");
  const lamentations = parseBookData(lamentationsData, "Lamentations");
  const ezekiel = parseBookData(ezekielData, "Ezekiel");
  const daniel = parseBookData(danielData, "Daniel");
  const hosea = parseBookData(hoseaData, "Hosea");
  const joel = parseBookData(joelData, "Joel");
  const amos = parseBookData(amosData, "Amos");
  const obadiah = parseBookData(obadiahData, "Obadiah");
  const jonah = parseBookData(jonahData, "Jonah");
  const micah = parseBookData(micahData, "Micah");
  const nahum = parseBookData(nahumData, "Nahum");
  const habakkuk = parseBookData(habakkukData, "Habakkuk");
  const zephaniah = parseBookData(zephaniahData, "Zephaniah");
  const haggai = parseBookData(haggaiData, "Haggai");
  const zechariah = parseBookData(zechariahData, "Zechariah");
  const malachi = parseBookData(malachiData, "Malachi");

  return [
    genesis,
    exodus,
    leviticus,
    numbers,
    duet,
    joshua,
    judges,
    ruth,
    iSamuel,
    iiSamuel,
    iKings,
    iiKings,
    iChron,
    iiChron,
    ezra,
    nehemiah,
    esther,
    job,
    psalms,
    proverbs,
    ecclesiastes,
    songOfSolomon,
    isaiah,
    jeremiah,
    lamentations,
    ezekiel,
    daniel,
    hosea,
    joel,
    amos,
    obadiah,
    jonah,
    micah,
    nahum,
    habakkuk,
    zephaniah,
    haggai,
    zechariah,
    malachi,
  ];
}
