import styled from "styled-components";

export const Button = styled.button`
  all: unset;
  cursor: pointer;

  background-color: #8b3dff;
  color: white;
  border-radius: 4px;
  padding: 8px 16px;

  transition: background-color 0.2s;

  &:hover {
    background-color: #9e77f3;
  }
`;
