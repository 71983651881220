import _ from "lodash";

import { Book } from "./book";
import { Chapter } from "./chapter";
import { Verse } from "./verse";

export function parseBookData(data, name) {
  const chapters = _.values(_.groupBy(data, (verse) => verse.id.slice(2, 5)))
    .map((chapterOfVerses) => {
      if (chapterOfVerses.length === 0) {
        return null;
      }
      const chapterNumber = parseInt(chapterOfVerses[0].id.slice(2, 5), 10);
      const verses = chapterOfVerses
        .map((rawVerse) => {
          const verseNumber = rawVerse.id.slice(-3);
          return new Verse(verseNumber, rawVerse);
        })
        .sort((a, b) => a.number - b.number);
      return new Chapter(chapterNumber, verses);
    })
    .sort((a, b) => a.number - b.number);
  return new Book(name, chapters);
}
