import * as mobx from "mobx";
import * as React from "react";
import seedColor from "seed-color";
import styled from "styled-components";

import { CrossIcon } from "./CrossIcon.js";
import { TrashIcon } from "./TrashIcon.js";

const GroupContainer = styled.div`
  max-width: 200px;
  padding: 16px;
  margin: 48px 0;
  border-radius: 4px;
  background-color: #e0e0f0;
  color: black;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    cursor: pointer;
    svg {
      height: 16px;
      color: red;
    }
  }
`;
const WordsContainer = styled.div`
  margin-top: 16px;
`;
const WordContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  font-size: 10pt;

  margin-top: 8px;

  border: 4px solid;
  border-radius: 4px;
  padding: 0 8px;

  & > div {
    cursor: pointer;

    svg {
      height: 16px;

      fill: red;
      padding: 4px;
    }
  }
`;
const TrashButton = styled.img`
  height: 16px;
  cursor: pointer;
  color: white;
`;

export const GroupControls = ({ group, removeFromGroup, clearGroup }) => {
  return (
    <GroupContainer>
      <TitleContainer>
        <div>Word Group</div>
        <div onClick={mobx.action(clearGroup)}>
          <TrashIcon />
        </div>
      </TitleContainer>
      <WordsContainer>
        {group.map((word, i) => (
          <WordContainer
            style={{
              borderColor: seedColor(`col-${i}`).toHex(),
            }}
            key={word}
          >
            <div>{word}</div>
            <div onClick={mobx.action(() => removeFromGroup(word))}>
              <CrossIcon />
            </div>
          </WordContainer>
        ))}
      </WordsContainer>
    </GroupContainer>
  );
};
