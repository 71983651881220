export class Book {
  constructor(name, chapters) {
    this.name = name;
    this.chapters = chapters;
  }

  containsWord(word) {
    return this.chapters.some((chapter) => chapter.containsWord(word));
  }

  getFrequency(word) {
    return this.chapters.map((chapter) => chapter.getFrequency(word)).flat();
  }
}
