import * as mobx from "mobx";

export class InterlinearStore {
  constructor(books) {
    mobx.extendObservable(this, {
      books: books,
      word: "h3206",
      book: null,
      view: "books",
      group: [],
    });
  }
}

export class InterlinearPresenter {
  constructor() {
    this.setBook = this.setBook.bind(this);
    this.setView = this.setView.bind(this);
    this.setWord = this.setWord.bind(this);
    this.showBooks = this.showBooks.bind(this);
    this.onWordChange = this.onWordChange.bind(this);
    this.selectBook = this.selectBook.bind(this);
    this.addCurrentWordToGroup = this.addCurrentWordToGroup.bind(this);
    this.removeFromGroup = this.removeFromGroup.bind(this);
    this.clearGroup = this.clearGroup.bind(this);
  }

  addCurrentWordToGroup(store) {
    if (!store.group.includes(store.word)) {
      store.group.push(store.word);
    }
  }

  removeFromGroup(store, word) {
    const index = store.group.findIndex((w) => w === word);

    if (index !== -1) {
      store.group.splice(index, 1);
    }
  }

  clearGroup(store) {
    store.group.splice(0, store.group.length);
  }

  createStore(books) {
    return new InterlinearStore(books);
  }

  showBooks(store) {
    this.setBook(store, null);
    this.setView(store, "books");
  }

  selectBook(store, book) {
    this.setBook(store, book);
    this.setView(store, "chapter");
  }

  onWordChange(store, e) {
    this.setWord(store, e.target.value);
  }

  setBook(store, book) {
    store.book = book;
  }

  setView(store, view) {
    store.view = view;
  }

  setWord(store, word) {
    store.word = word;
  }
}
