export class Chapter {
  constructor(number, verses) {
    this.number = number;
    this.verses = verses;
  }

  containsWord(word) {
    return this.verses.some((verse) => verse.containsWord(word));
  }

  getFrequency(word) {
    return this.verses.map((verse) => verse.containsWord(word)).flat();
  }
}
