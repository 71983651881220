import _ from "lodash";
import * as React from "react";
import seedColor from "seed-color";
import styled from "styled-components";

const FrequencyIndicator = styled.div`
  height: ${(props) => (props.size === "medium" ? "80px" : "40px")};
  position: relative;
  border: 1px solid lightslategray;
  border-radius: 4px;
  background-color: #ffffff;

  cursor: ${({ clickable }) => (clickable ? "pointer" : "auto")};

  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ clickable }) => (clickable ? "#fbfbfb" : "#fff")};
  }
`;

const WordPresent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${(props) => (props.size === "medium" ? "1px" : "4px")};

  display: flex;
  flex-direction: column;
`;

const WordMarker = styled.div`
  flex: 1;
`;

export function WordFrequency({ wordFrequency, onClick, size = "medium" }) {
  const factor = size === "small" ? 4 : 1;
  return (
    <FrequencyIndicator
      style={{ width: wordFrequency[0].length * factor }}
      onClick={onClick}
      size={size}
      clickable={!!onClick}
    >
      {_.unzip(wordFrequency).map(
        (wordsPresent, i) =>
          wordsPresent.some((i) => i) && (
            <WordPresent size={size} style={{ left: i * factor }} key={i}>
              {wordsPresent.map(
                (present, j) =>
                  present && (
                    <WordMarker
                      key={j}
                      style={{
                        backgroundColor: seedColor(`col-${j}`).toHex(),
                      }}
                    />
                  )
              )}
            </WordPresent>
          )
      )}
    </FrequencyIndicator>
  );
}
