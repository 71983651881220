import * as React from "react";
import styled from "styled-components";

import { Button } from "./shared";
import { WordFrequency } from "./WordFrequency";

const ChapterContainer = styled.div`
  margin-bottom: 16px;
`;
const ChapterName = styled.h3`
  text-align: left;
`;
const ChaptersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
`;

const BookContainer = styled.div`
  position: relative;
  margin: 24px 0;
  overflow: scroll;
`;
const BookName = styled.h2`
  text-align: left;
  margin-top: 8px;
`;

export function BookFrequency({ book, onSelect, words }) {
  return (
    <BookContainer clickable>
      <BookName>{book.name}</BookName>
      <WordFrequency
        wordFrequency={words.map((word) => book.getFrequency(word))}
        onClick={onSelect}
      />
    </BookContainer>
  );
}

export function ChapterFrequency({ book, onBack, words }) {
  return (
    <BookContainer>
      <Button onClick={onBack}>Back</Button>
      <BookName>{book.name} - Chapters</BookName>
      <ChaptersContainer>
        {book.chapters.map((chapter) => (
          <ChapterContainer key={chapter.number}>
            <ChapterName>{chapter.number}</ChapterName>
            <WordFrequency
              wordFrequency={words.map((word) => chapter.getFrequency(word))}
              size="small"
            />
          </ChapterContainer>
        ))}
      </ChaptersContainer>
    </BookContainer>
  );
}
