import _ from "lodash";
import * as React from "react";
import styled from "styled-components";

import hebrewLexicon from "../bible_data/hebrew.json";

const SectionTitle = styled.div`
  font-size: 8pt;
  font-color: #606060;
  font-weight: bold;
  margin-bottom: 6px;
`;

const WordName = styled.div`
  font-size: 14pt;
  text-style: italic;
  margin-bottom: 8px;
`;

const WordShortDefinition = styled.div`
  font-size: 10pt;
  margin-bottom: 8px;
`;

export function WordDefinition({ word }) {
  const wordData = hebrewLexicon.find((data) => data.strongs === word);

  if (!wordData) {
    return <div>Not found!</div>;
  }

  const firstLongDef = _.get(wordData, "data.def.long.0");
  const wordName =
    firstLongDef == null
      ? wordData.word
      : firstLongDef.length < wordData.word.length
      ? firstLongDef
      : wordData.word;

  return (
    <div>
      <SectionTitle>Definition:</SectionTitle>
      <WordName>{wordName}</WordName>
      <WordShortDefinition>{wordData.data.def.short}</WordShortDefinition>
    </div>
  );
}
