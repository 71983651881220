import "./App.css";

import * as mobx from "mobx";
import * as mobxReact from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import { getOldTestamentBooks } from "./bible/data";
import { InterlinearPresenter } from "./presenter";
import { BookFrequency, ChapterFrequency } from "./ui/BookFrequency";
import { GroupControls } from "./ui/GroupControls";
import { Button } from "./ui/shared";
import { WordDefinition } from "./ui/WordDefinition";

function App() {
  const presenter = new InterlinearPresenter();
  const store = presenter.createStore(getOldTestamentBooks());
  return (
    <PageContainer>
      <Controls presenter={presenter} store={store} />
      <BooksFrequency presenter={presenter} store={store} />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  padding: 50px;

  width: calc(100% - 100px);
  overflow: scroll;

  background: linear-gradient(135deg, #fafafa10 0%, #f0f0f0ff 100%);
`;

const BooksFrequency = mobxReact.observer(({ presenter, store }) => {
  let selectedWords = store.group;
  const index = store.group.findIndex((w) => w === store.word);
  if (index === -1) {
    selectedWords = [...store.group, store.word];
  }

  return (
    <div>
      {store.books.map((book) =>
        store.view === "books" ? (
          <BookFrequency
            book={book}
            onSelect={mobx.action(() => presenter.selectBook(store, book.name))}
            words={selectedWords}
            key={book.name}
          />
        ) : store.view === "chapter" && store.book === book.name ? (
          <ChapterFrequency
            book={book}
            onBack={mobx.action(() => presenter.showBooks(store))}
            words={selectedWords}
          />
        ) : null
      )}
    </div>
  );
});

const WordEntryContainer = styled.div`
  margin-bottom: 16px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  & > * {
    margin-right: 16px;
  }
`;
const WordLabel = styled.label`
  font-size: 18pt;
  margin-right: 8px;
`;
const WordInput = styled.input`
  font-size: 18pt;
  border: none;
  border-bottom: 1px solid #8b3dff;
  outline: none;

  transition: box-shadow 0.2s;

  &:focus {
    box-shadow: inset 0 -2px 0 #8b3dff;
  }
`;

const Controls = mobxReact.observer(({ presenter, store }) => {
  return (
    <div>
      <WordEntryContainer>
        <WordLabel>Word:</WordLabel>
        <WordInput
          type="text"
          value={store.word}
          onChange={mobx.action((e) => presenter.onWordChange(store, e))}
        />
        <Button
          onClick={mobx.action(() => presenter.addCurrentWordToGroup(store))}
        >
          Add To Group
        </Button>
      </WordEntryContainer>
      <WordDefinition word={store.word} />
      {store.group.length > 0 && (
        <GroupControls
          group={store.group}
          removeFromGroup={(word) => presenter.removeFromGroup(store, word)}
          clearGroup={() => presenter.clearGroup(store)}
        />
      )}
    </div>
  );
});

export default App;
